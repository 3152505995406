<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <b-button variant="primary" @click="addModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="items" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="warning" @click="openRoomEdit(props.row)" class="mr-1">
                            <feather-icon
                                icon="EditIcon"
                            />
                        </b-button>
                        <b-button variant="danger" @click="removeItem(props.row.id)">
                            <feather-icon
                                icon="Trash2Icon"
                            />
                        </b-button>
                    </div>
                    <span v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <b-badge v-if="props.row.fully_translated" variant="light-success">
                            Yes
                        </b-badge>
                        <b-badge v-else variant="light-danger">
                            No
                        </b-badge>
                    </span>
                    <div v-else-if="props.column.displayType === 3">
                        <b>{{ props.formattedRow[props.column.field] }}</b>
                        <p style="max-height: 60px;overflow: hidden; text-overflow: ellipsis; word-wrap: break-word; display: block;">
                            {{ props.row.description }}
                        </p>
                    </div>
                    <div v-else-if="props.column.displayType === 4">
                        <b>{{ props.formattedRow[props.column.field] }}</b>
                        <p style="max-height: 60px;overflow: hidden; text-overflow: ellipsis; word-wrap: break-word; display: block;"  v-if="roomsERP">
                            {{ (roomsERP.find(room => room.id.toString() === props.row.erp_id) | {name: 'Unknown'}).name }}
                        </p>
                    </div>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>

        <b-modal title="Add new" v-model="addModalActive" no-close-on-backdrop>
            <template #default>
                <b-form-group>
                    <label for="location">Location:</label>
                    <b-form-input
                        id="location"
                        type="text"
                        placeholder="Location"
                        v-model="addObject.location"
                    />
                </b-form-group>
                <b-form-group>
                    <label for="name">Name:</label>
                    <b-form-input
                        id="name"
                        type="text"
                        placeholder="Name"
                        v-model="addObject.name"
                    />
                </b-form-group>
                <b-form-group>
                    <label for="erp_id">ERP ID:</label>
                    <v-select
                        id="erp_id"
                        v-model="addObject.erp_id"
                        :reduce="room => room.id.toString()"
                        label="name"
                        :options="roomsERP"
                        v-if="roomsERP.length > 0"
                    />
                    <b-form-input v-else
                                  v-model="addObject.erp_id"
                                  label="name"
                    />
                </b-form-group>
                <b-form-group>
                    <label for="description">Description:</label>
                    <b-form-textarea
                        id="description"
                        type="text"
                        placeholder="Description"
                        v-model="addObject.description"
                    />
                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="addModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addItem">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>


        <b-modal title="Edit room" size="xl" v-model="editModalActive" no-close-on-backdrop>
            <template #default>
                <b-form-group>
                    <label>Location:</label>
                    <b-form-input
                        type="text"
                        placeholder="Location"
                        v-model="editObject.location"
                    />
                </b-form-group>
                <b-form-group>
                    <label>Name:</label>
                    <b-form-input
                        type="text"
                        placeholder="Name"
                        v-model="editObject.name"
                    />
                </b-form-group>
                <b-form-group>
                    <label for="erp_idE">ERP ID:</label>
                    <v-select
                        id="erp_idE"
                        v-model="editObject.erp_id"
                        :reduce="room => room.id.toString()"
                        label="name"
                        :options="roomsERP"
                        v-if="roomsERP.length > 0"
                    />
                    <b-form-input v-else
                                  v-model="editObject.erp_id"
                                  label="name"
                    />
                </b-form-group>
                <b-form-group>
                    <label>Description:</label>
                    <b-form-textarea
                        type="text"
                        placeholder="Description"
                        v-model="editObject.description"
                    />
                </b-form-group>

                <hr/>

                <Translations class="mt-3" :base-url="`/api/management/v1/room/${  editObject.id}`" :fields="[{name: 'Location', field: 'location', type: 0}, {name: 'Name', field: 'name', type: 0}, {name: 'Description', field: 'description', type: 1}]" v-on:translationsChanged="loadData"/>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="editModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="warning" @click="saveItem">
                    <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save</span>
                </b-button>
            </template>
        </b-modal>

    </div>
</template>
<script>

    import { BOverlay, BCard, BButton, BModal, BFormInput, BFormTextarea, BFormGroup, BBadge } from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import Translations from '@/views/Translation/Translations'
    import vSelect from 'vue-select'

    export default {
        components: {
            Translations,
            BOverlay,
            BCard,
            BButton,
            BModal,
            BFormInput,
            BFormTextarea,
            BFormGroup,
            BasicTable,
            BBadge,
            vSelect
        },
        data() {
            return {

                items: [],
                dataLoaded: false,
                roomsERP: [],
                columns: [
                    {
                        label: 'Location',
                        displayType: 0,
                        field: 'location',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search location'
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 3,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Name'
                        }
                    },
                    {
                        label: 'ERP ID',
                        displayType: 4,
                        field: 'erp_id',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search ERP ID'
                        }
                    },
                    {
                        label: 'Fully translated',
                        displayType: 2,
                        field(rowObj) {
                            return (rowObj.fully_translated) ? 'Yes' : 'No'
                        },
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Yes / No'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                addObject: {},
                addModalActive: false,

                editObject: {},
                editModalActive: false
            }
        },
        methods: {
            openRoomEdit(room) {
                this.editObject = JSON.parse(JSON.stringify(room))
                this.editModalActive = true
            },
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const promises = []
                const loadPromise = this.$http.get('/api/management/v1/room')
                loadPromise.then(function(response) {
                    thisIns.items = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                promises.push(loadPromise)

                if (this.$store.getters['app/integrationEnabled'](1)) {
                    const roomPromise = this.$http.get('/api/integrations/clocks/v1/rooms')
                    roomPromise.then(function(response) {
                        thisIns.roomsERP = response.data
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                    })

                    promises.push(roomPromise)
                }

                Promise.all(promises).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addItem() {
                const thisIns = this
                const loadPromise = this.$http.post('/api/management/v1/room', this.addObject)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Item added')
                    thisIns.addModalActive = false
                    thisIns.addObject = {}
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            saveItem() {
                const thisIns = this
                const loadPromise = this.$http.put(`/api/management/v1/room/${  this.editObject.id}`, this.editObject)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Item saved')
                    thisIns.editModalActive = false
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            removeItem(id) {
                const thisIns = this
                const loadPromise = this.$http.delete(`/api/management/v1/room/${  id}`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Room removed')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
